.collection-parent {
    margin-top: 40px;
    padding: 40px 0;
    padding-bottom: 60px;
}

.collection-parent h5 {
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 35px;
}

.collection-parent .collection-card {
    border-radius: 5px;
    position: relative;
    height: 100%;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    padding: 20px;
    text-align: center;
}

.collection-parent .collection-card p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.1rem;
    color: #6b6d70;
    margin-bottom: 20px;
}

.collection-parent .collection-card h6 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    border-bottom: 1px solid #6b6d703d;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.collection-parent .collection-card button {
    background: #2F6DB5;
    color: white;
    border: none;
    line-height: 1.5;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
}

.collection-parent .area-section {
    border-radius: 5px;
    position: relative;
    height: 100%;
    border: none;
    background: white !important;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    padding: 20px;
    text-align: center;
}

.collection-parent img {
    width: 200px;
}

.collection-parent p {
    color: #6b6d70;
}

@media screen and (max-width: 768px) {
    .collection-parent .col-md-4 {
        margin-bottom: 20px !important;
    }
}