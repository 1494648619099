.use-app{
    text-align: center;
    padding-bottom: 60px;
}

.use-app h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 35px;
}

.use-app button {
    background: #010202;
    padding: 0;
    border: none;
    margin: 0 30px;
    border-radius: 10px;
    box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
}

@media screen and (max-width: 768px) {
    .use-app .social-content img {
        width: 100%;
    }

    .use-app button {
        margin: 0 20px;
    }
    
}
