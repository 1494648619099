.airport-places {
    padding: 40px 0;
}

.airport-places h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
}


.airport-places p{
    color: #777E90;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.1rem;
}

.airport-places img{
    width: 100%;
    border-radius: 10px;
}

.airport-places button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 20px 0;
}

.airports-parent .use-app{
    background: transparent;
    padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
    .airport-places .head-section{
flex-direction: column-reverse;
    }

    .airport-places .col-md-6{
        margin-bottom: 20px;
    }
    
}

