.driver-partner {
    padding: 20px 0;
    margin-top: 30px;
}

.driver-partner img {
    width: 100%;
    border-radius: 10px;
}

.driver-partner h5 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin: 25px 0;
}

.driver-partner h6 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
}

.driver-partner p {
    line-height: 1.5;
    font-size: 1.1rem;
    color: #6b6d70;
}

.driver-partner .row {
    align-items: center;
}

.ltr .driver-partner .number {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #2F6DB5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 10px;
    font-size: 14px;
}

.rtl .driver-partner .number {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #2F6DB5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-left: 10px;
    font-size: 14px;
}


.driver-partner .d-flex {
    margin-bottom: 25px;
}


@media screen and (max-width: 768px) {
    .driver-partner {
        margin-top: 0;
    }

    .driver-partner .row {
        flex-direction: column-reverse;
    }

    .driver-partner img {
        margin-bottom: 30px;
    }

    .register-text {
        width: 90%;
    }
}