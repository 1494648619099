body {
  margin: 0 !important;
  padding: 0 !important;
}

.App {
  font-family: 'Tajawal', sans-serif;
}

.App .container {
  padding: 0 20px;
}

.home-parent,
.our-story-parent,
.ride-parent,
.drive-parent,
.careers-parent,
.safety-parent,
.cities-parent,
.airports-parent,
.contact-parent,
.incentive-program, .privacy-parent ,.term-conditions {
  min-height: calc(100vh - 670px);
}

.ride-parent .use-app,
.incentive-program {
  padding-top: 40px;
}

.contact-us {
  padding: 20px 0;
}

.contact-us .back-home-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 90px;
  border: 2px solid #E6E8EC;
  padding: 12px 16px;
  color: #23262F;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  background: transparent;
}

.contact-us p {
  margin: 0;
}

.contact-us .back-home-btn span {
  margin-right: 10px;
}

.contact-us .back-routes {
  color: #777E90;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}

.contact-us .back-routes span {
  margin: 0 8px;
}

.contact-us h5 {
  color: #23262F;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 10px;
}

.contact-us .contact-form {
  border-radius: 10px;
  background: #2F6DB5;
  box-shadow: 0px 27px 16px 5px rgba(222, 222, 222, 0.15);
  padding: 30px 80px;
  margin-top: 40px;
}

.contact-us .contact-input {
  border-radius: 10px;
  border: 2px solid #E2E8F0;
  outline: none;
  padding: 10px 16px;
  color: #FCFCFD !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  background: transparent;
}

.contact-us .contact-area {
  width: 100%;
  background: transparent;
  border-radius: 15px;
  border: 1px solid #E2E8F0;
  height: 247px;
  outline: none;
  color: #FCFCFD;
  padding: 10px 16px;
}

.contact-us .form-input {
  margin-top: 30px;
}

.contact-us h6 {
  color: #FCFCFD;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 14px;
}

.contact-us input::placeholder,
.contact-us textarea::placeholder {
  color: #FCFCFD;
}

.contact-us .confirm-btn {
  border-radius: 90px;
  background: #FCFCFD;
  height: 48px;
  color: #2F6DB5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border: none;
  width: 100%;
  margin: 40px 0 30px 0;
}

.contact-us .company-info {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 27px 16px 5px rgba(222, 222, 222, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin-top: 40px;
  text-align: center;
}

.contact-us .company-info h6 {
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.contact-us .company-info .info-icon {
  font-size: 25px;
}

.contact-us a {
  text-decoration: none;
}

.incentive-program img {
  width: 90%;
}

.incentive-program h5 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 30px;
  margin-bottom: 25px;
}

.incentive-program h6 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-top: 5px;
}

.incentive-program h2 {
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.2rem;
  margin-top: 5px;
}

.incentive-program p {
  line-height: 1.5;
  font-size: 1.1rem;
  color: #6b6d70;
}

.loader-parent {
  height: 100vh;
}

@keyframes ld-fade {
  0% {
    animation-timing-function: cubic-bezier(0.2057, 0.573, 0.3723, 0.9184);
    opacity: 1
  }

  100% {
    opacity: 0.2
  }
}

@keyframes moveUpDown2 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }

  0% {
    animation-timing-function: cubic-bezier(0.2057, 0.573, 0.3723, 0.9184);
    opacity: 1
  }

  100% {
    opacity: 0.2
  }
}

.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 13, 19, 0.9);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}


.loader__wrapper .loader li {
  background: linear-gradient(270deg, #007EFF 0%, #4FA6FF 100%);
  margin-left: 1px;
  width: 20px;
  height: 30px;
  display: inline-block;
  opacity: 0;
  border-radius: 2px;
  transform: skew(-25deg, 0deg) scale(0.1);
  -webkit-animation: loader 0.5s ease-in-out infinite alternate;
  animation: loader 0.5s ease-in-out infinite alternate;
  animation: loader .5s ease-in-out infinite alternate;
}

#spiner-loader {
  animation: moveUpDown2 1s infinite linear;
}

.loader li:nth-child(2) {
  animation-delay: 0.2s
}

.loader li:nth-child(3) {
  animation-delay: 0.5s
}

.loader li:nth-child(4) {
  animation-delay: 0.5s
}

.loader li:nth-child(5) {
  animation-delay: 0.7s
}

.loader li:nth-child(6) {
  animation-delay: 0.8s
}

.loader li:nth-child(7) {
  animation-delay: 1s
}


.Toastify__toast--error,
.Toastify__toast--success {
  background: #2F6DB5 !important;
  color: white !important;
  font-family: 'Almarai', sans-serif;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #e74c3c !important;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #07bc0c !important;
}

.Toastify__close-button > svg {
    color: white !important;
  }

/* .Toastify__close-button > svg {
  color: gray !important;
} */

.Toastify__close-button > svg:hover {
  color: black !important;
}
.Toastify__toast {
  margin-top: 15px;
  z-index: 999999;
}

.loader-btn {
  width: 25px;
  height: 25px;
  border: 2px solid #2F6DB5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
  }

  .loader-btn2 {
    width: 25px;
    height: 25px;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

@keyframes loader {
  to {
    transform: skew(-25deg, 0deg) scale(1);
    opacity: 1
  }
}


@media screen and (max-width: 768px) {
  .contact-us h5 {
    font-size: 30px;
  }

  .contact-us .contact-form {
    padding: 20px 40px;
  }

  .contact-us .contact-area {
    height: 140px;
  }

  .contact-us .company-info {
    flex-direction: column;
  }


  .incentive-program img {
    width: 100%;
  }

  .incentive-program .col-md-5,
  .incentive-program .col-md-7,
  .incentive-program .col-md-12 {
    margin-bottom: 20px !important;
  }

  .incentive-program .head-sec {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contact-us h5 {
    font-size: 30px;
  }
}