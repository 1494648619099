.story-parent .header {
  background-image: url("../../Assets/slide9.png");
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.story-parent .transperant-bg {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 300px;
}

.story-parent .header h5 {
  background-color: #11304e69;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  padding: 20px 0;
}

.story-parent .story-content {
  padding: 30px 0 0 0;
}

.story-parent .story-content h6 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 2rem;
  margin-bottom: 15px;
}

.story-parent .story-content p {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.3rem;
  color: #6b6d70;
  padding: 10px;
  margin: 0;
}


.story-parent .phone-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-parent .phone-img img {
  width: 40%;
}