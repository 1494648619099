
.become-driver .content-section{
    padding: 40px 200px;
}

.become-driver .driver-card img{
    width: 100%;
    height: 100%;
}

.become-driver .driver-card{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.become-driver .driver-card .overlay-img{
    position: absolute;
    top: 0;
background: rgba(0, 0, 0, 0.46);
width: 100%;
height: 100%;
}

.ltr .become-driver .driver-card .text-overlay{
    position: absolute;
    top: 10%;
    left: 10%;
    color: white;
}

.rtl .become-driver .driver-card .text-overlay{
    position: absolute;
    top: 10%;
    right: 10%;
    color: white;
}

.become-driver .driver-card .text-overlay h6{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.become-driver .driver-card .text-overlay p{
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.become-driver .driver-card .text-overlay button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .become-driver .content-section {
        padding: 20px;
    }

    .become-driver .col-md-6{
        margin-bottom: 20px;
    }
}