.career-header {
    margin: 20px 0;
}

.career-header img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    flex: 1;
}

.career-header h5 {
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.career-header h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.career-header p {
    line-height: 1.5;
    font-size: 1.1rem;
    color: #6b6d70;
}

.career-header .col-md-6 {
    display: flex;
    flex-direction: column;
}

.career-header .row1 {
    align-items: center;
}

@media screen and (max-width: 768px) {
    .career-header .row1 {
        flex-direction: column-reverse;
    }

    .career-header .flex-mob {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}