.story-section3 .header3 {
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.story-section3 .transperant-bg3 {
    background-color: rgba(0, 0, 0, 0.612);
    width: 100%;
    height: 260px;
}

.story-section3 .header3 h5 {
    margin: 0;
    padding: 10px 0;
    color: white;
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    padding-top: 30px;
}

.story-section3 .header3 p {
    margin: 0;
    padding: 10px 0;
    color: white;
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.5rem;
}

.story-section3 .content-ship{
    padding-top: 20px;
}

.story-section3 .team-info {
    padding: 40px 0 20px 0;
}

.story-section3 .team-info .row {
    padding: 0 200px;
}

.story-section3 .team-info .col-md-3 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-section3 .team-info .card-body {
    padding: 10px 5px;
}

.story-section3 .team-info .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #1B1A1D;
}

.story-section3 .team-info .card {
    border-radius: 5px;
    position: relative;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    text-align: center;
    width: 180px;
    flex: 1;
}


.story-section3 .team-info .card .head-title p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6b6d70;
    margin-bottom: 3px;
}

.story-section3 .team-info .card-img-top {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .story-section3 .team-info .row {
        padding: 0;
    }

    .story-section2 .row {
        flex-direction: column-reverse;
    }

}