.footer {
   background: #010202;
    padding: 40px 0;
}

.footer-content {
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 25px;
}

.footer-content h5 {
    white-space: nowrap;
    color: #FFFFFF;
    margin: 40px 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
}


.footer-content h6 {
    white-space: nowrap;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
}

.footer-content p {
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 20px;
    width: fit-content;
    transition: all 0.5s ease;
}

.footer-content p:hover {
    color: #005C9A;
}

.rtl .footer .media-icons {
    color: white;
    cursor: pointer;
    font-size: 30px;
}

.ltr .footer .media-icons{
    color: white;
    cursor: pointer;
    font-size: 30px;
}

.company-logo {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.all-rights p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 0;
}

.rtl .footer .social-content button {
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
}

.ltr .footer .social-content button {
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
}

.footer a {
    text-decoration: none;
}

.footer .navbar-nav p{
    margin: 0;
}

.ltr .footer .all-rights a{
    margin-right: 25px ;
}

.rtl .footer .all-rights a{
    margin-left: 25px ;
}


@media screen and (max-width: 768px) {
    .footer-content .col-md-2 {
        margin-bottom: 40px;
    }

    .footer .media-icons {
        margin: 0 5px;
        font-size: 25px !important;
    }

    .company-logo {
        width: 20px;
        height: 20px;
    }

    .footer-content p {
        font-size: 14px;
    }

    .footer-content h6 {
        font-size: 16px;
    }

    .footer-content {
        padding-bottom: 0;
    }

    .footer .all-rights {
        flex-direction: column;
    }

    .footer .social-content {
        margin-top: 30px;
    }

    .footer .social-content img {
        width: 70%;
    }

}