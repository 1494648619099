
.start-drive{
    text-align: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.start-drive h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.start-drive button {
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .start-drive{
        text-align: center;
        padding-bottom: 20px;
        padding-top: 10px;
    }
    
}