.limo-manager {
    padding: 20px 0;
}

.limo-manager img {
    width: 100%;
}

.limo-manager .row {
    align-items: center;
    padding: 0 80px;
}

.limo-manager p {
    font-size: 18px;
    font-weight: normal;
    line-height: 35px;
    color: #6b6d70;
}

.limo-manager h6 {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-top: 5px;
}

.limo-manager h5 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-top: 5px;
}

@media screen and (max-width: 768px) {
    .limo-manager .row {
        padding: 0;
        flex-direction: column-reverse;
    }

    .limo-manager .col-md-5,
    .limo-manager .col-md-7 {
        margin-bottom: 20px;
    }
}