.career-form{
    margin: 40px 0;
}

.career-form .career-card{
    position: relative;
    border: none;
    box-shadow: 0px 1px 5px rgba(83, 83, 83, 0.1);
    padding: 20px 40px;
}

.career-form .head-card{
    position: relative;
    border: none;
    box-shadow: 0px 1px 5px rgba(83, 83, 83, 0.1);
    padding: 20px 40px;
    border-bottom: 2px solid rgba(83, 83, 83, 0.1);
}

.career-form h4{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
}

.career-form h5{
    color: #010202;
    line-height: 1.2;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(83, 83, 83, 0.1);
}

.career-form .form-input input{
    border-radius: 5px;
    border: 2px solid #E6E8EC;
    outline: none;
    padding: 10px 16px;
    color: #777E90;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    background: #F9F9F9;
}

.career-form .form-input{
    width: 40%;
    margin: 40px 0;
}

.career-form select ,.career-form textarea{
    border-radius: 5px;
    border: 2px solid #E6E8EC;
    outline: none;
    padding: 10px 16px;
    color: #777E90;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    background: #F9F9F9;
    outline: none;
    width: 40%;
    margin-bottom: 20px ;
  }

  .career-form .upload-sec{
    margin: 40px;
  }

  .career-form .select-option select{
    width: 40%;
  }

  .career-form button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    border-radius: 5px;
    margin: 10px 0;
    height: 48px;
    width: 148px;
  }

  .career-form .form-check{
    margin: 10px 0;
  }

  .career-form h6{
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
}

.career-form p{
margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .career-form .form-input ,.career-form .select-option select ,.career-form select, .career-form textarea{
        width: 100%;
    }

    .career-form .upload-sec{
        margin: 0;
    }
}