.most-questions {
  padding: 40px 0;
}

.most-questions h5 {
  color: #23262F;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
}


.most-questions .accordion-body {
  color: #6b6d70;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  padding-right: 0;
  padding-left: 0;
}

.most-questions .accordion-item:first-of-type .accordion-button {
  color: #23262F;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.1rem;
  background: transparent;
  box-shadow:none !important;
  border-radius: none !important;
  padding-right: 0;
  padding-left: 0;
  justify-content: space-between;
}

.most-questions .accordion{
  margin: 10px 0;
  border-bottom: 1px solid #c5c7ca;
}

.most-questions .accordion-item:last-of-type{
  border: none !important;
}
