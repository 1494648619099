.safety-section4 {
    padding: 40px 0;
}

.safety-section4 h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.safety-section4 p{
    color: #777E90;
    line-height: 1.5;
    font-size: 1.1rem;
}

.safety-section4 img{
    width: 100%;
}

.safety-section4 .card{
    border: none !important;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    border: none;
}


.safety-section4 .card-title{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
}

.safety-section4 .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    padding: 0 60px;
}


@media screen and (max-width: 768px) {
    .safety-section4{
        padding: 10px 0;
    }

    .safety-section4 .grid{
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 0;
    }
}
