.ride-area{
    padding: 40px 0;
}

.ride-area h5{
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin-bottom: 25px;
   
}

.ride-area .area-section button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
}

.ride-area p{
    line-height: 1.5;
    font-size: 1.1rem;
}

.ride-area h6{
    font-weight: 700;
    margin-top: 5px;
    line-height: 1.2;
    font-size: 1.5rem;
}

.ride-area .area-section{
    border-radius: 5px;
    position: relative;
    height: 100%;
    border: none;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    padding: 20px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .ride-area h5 {
        line-height: 1.2;
        font-size: 30px;
    }

    .ride-area{
        padding: 0;
    }
    
    .ride-area .col-md-4{
        margin-bottom: 20px;
    }
    
}

