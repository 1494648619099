.safety-section2 {
    padding: 40px 0;
}
.safety-section2 h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.safety-section2 p{
    color: #777E90;
    line-height: 1.5;
    font-size: 1.1rem;
}

.safety-section2 h6{
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.1rem;
}

.safety-section2 img{
    width: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .safety-section2 .head-sec{
        flex-direction: column-reverse;
    }

    .safety-section2 .sec2{
        margin-bottom: 20px;
    }

    .safety-section2{
        padding-bottom: 0 !important;
    }

}

