.why-limo{
    padding: 40px 0;
}


.ltr .why-limo img{
    width: 35%;
    padding-right: 50px;
    animation: moveUpDown 2s infinite;
}

.rtl .why-limo img{
    width: 35%;
    padding-left: 50px;
    padding-right: 0;
    animation: moveUpDown 2s infinite;
}

.why-limo h5{
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin-bottom: 25px;
   
}

.why-limo .limo-feature{
    margin-bottom: 30px;
}


.ltr .why-limo .limo-feature .icon {
    font-size: 30px;
    color: #2F6DB5;
    margin-right: 5px;
}

.rtl .why-limo .limo-feature .icon {
    font-size: 30px;
    color: #2F6DB5;
    margin-left: 5px;
}

.why-limo h6{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-top: 5px;
}

.why-limo p{
    line-height: 1.5;
    font-size: 1.1rem;
}

@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @media screen and (max-width: 768px) {
    .why-limo .d-flex{
        flex-direction: column;
    }
    .ltr .why-limo img{
        width: 80%;
        padding-right: 0;
        margin: 10px 0;
    }
    
    .rtl .why-limo img{
        width: 80%;
        padding-left: 0;
        margin: 10px 0;
    }
  }