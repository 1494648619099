.story-section2 .header2 {
    background-image: url("../../Assets/value2.jpg");
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.story-section2 .transperant-bg2 {
    background-color: rgba(0, 0, 0, 0.386);
    width: 100%;
    height: 260px;
}

.story-section2 .header2 h5 {
    position: absolute;
    bottom: 40%;
    width: 65%;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    color: white;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
}

.story-section2 .our-values {
    padding: 20px 0;
}

.ltr .story-section2 .our-values .icon {
    font-size: 25px;
    color: #2F6DB5;
    margin-right: 5px;
}

.rtl .story-section2 .our-values .icon {
    font-size: 25px;
    color: #2F6DB5;
    margin-left: 5px;
}

.story-section2 .our-values h6 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-bottom: 5px;

}

.story-section2 .our-values p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.2rem;
    color: #6b6d70;
    margin: 0;
}

.story-section2 .our-values .col-md-6 {
    margin-bottom: 30px;
}

.story-section2 .section2 img {
    width: 100%;
}

.story-section2 .section2 img {
    width: 100%;
    border-radius: 10px;
}

.story-section2 .row {
    align-items: center;
}

.story-section2 h5 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    padding-top: 30px;
    margin-bottom: 20px;
}

.story-section2 {
    margin-bottom: 60px;
}


@media screen and (max-width: 768px) {
    .story-section2 h5 {
        padding-top: 0 !important;
        margin-bottom: 20px;
    }

}