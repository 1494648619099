.travel-partener{
    position: relative;
}

.travel-partener h4{
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin: 40px 0;
}

.travel-partener .card h6{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
}

.travel-partener .card p{
    line-height: 1.5;
    font-size: 1.1rem;
    margin-bottom: 80px;
}

.travel-partener button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
}

.travel-partener .card {
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
    border: none;
    flex: 1;
}

.travel-partener .row{
    padding: 0 80px;
}

.travel-partener .col-md-6{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.travel-partener img{
    width: 100%;
    height: 280px;
}

@media screen and (max-width: 768px) {

.travel-partener .row{
    padding: 0;
}

}