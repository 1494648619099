.safety-section1 {
    padding: 40px 0;
}

.safety-section1 h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.safety-section1 p{
    color: #777E90;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.1rem;
}

.safety-section1 img{
    width: 100%;
    border-radius: 10px;
}


@media screen and (max-width: 768px) {
    .safety-section1 .row{
        flex-direction: column-reverse;
    }

    .safety-section1{
        padding-bottom: 0 !important;
    }

    .safety-section1 h5{
        margin: 20px 0 !important;
    }
}
