.drive-section h5 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin: 25px 0;
}

.drive-section img {
    margin-bottom: 30px;
    width: 70%;
}

.drive-section .col-md-6 {
    margin-bottom: 20px;
}

.rtl .drive-section span {
    color: #2F6DB5;
    font-size: 25px;
    margin-left: 10px;
}


.ltr .drive-section span {
    color: #2F6DB5;
    font-size: 25px;
    margin-right: 10px;
}


.drive-section h6 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
}

.drive-section p {
    line-height: 1.5;
    font-size: 1.1rem;
    color: #6b6d70;
}