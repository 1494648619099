.not-found {
  min-height: calc(100vh - 147px);
  text-align: center;
  padding: 30px;
  background: #F9F9F9;
}

.btn-notfound {
  background: #2F6DB5;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.5s ease;
  text-decoration: none !important;
  outline: none;
}

.btn-notfound:hover {
  background: #2F6DB5;
  color: white;
}

.not-found h2 {
  margin: 30px 0;
}

.not-found .btn {
  margin: 30px 0;
  border: none;
}

@media screen and (max-width: 768px) {
  .not-found h2 {
    font-size: 14px;
  }

  .btn-notfound {
    font-size: 12px;
  }
}