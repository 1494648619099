.slider-parent .carousel-inner{
    height: calc(100vh - 73px);
    overflow: hidden;
    position: relative;
}

.slider-parent{
  direction: ltr !important;
}

  .slider-parent img {
    height: 100%;
    overflow: hidden;
  }

  /* .rtl .slider-parent .legend h6{
    text-align: right;
  } */

  .slider-parent .legend h6{
    color: #FFFFFF;
    margin: 10px 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 2.5rem;
    width: 45%;
  }

  .slider-parent .legend h5{
    color: #FFFFFF;
    margin: 10px 0;
    font-weight: 700;
    line-height: 1.2;
    font-size: 3rem;
    width: 45%;
  }

  .slider-parent .legend p{
    color: #FFFFFF;
    margin: 10px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.5rem;
    width: 45%;
  }

  .slider-parent .legend button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 15px 0;
  }

  .slider-parent .carousel{
    display: none;
}

.slider-parent .carousel-slider {
    display: block !important;
    height: calc(100vh - 73px);
    overflow: hidden;
}

.slider-parent .carousel.carousel-slider .control-arrow ,.slider-parent .carousel .carousel-status{
    display: none;
}

.slider-parent .carousel .slide .legend{
    bottom: 55%;
    text-align: left;
    background: transparent;
    opacity: 1;
}

.rtl .slider-parent .carousel .slide .legend{
  bottom: 55%;
  text-align: right;
  background: transparent;
  direction: rtl;
  opacity: 1;
}

@media screen and (max-width: 768px) {
    .slider-parent .carousel .slide .legend{
      top: 10px;
        text-align: left;
        background: transparent;
        opacity: 1;
    }
    
    .slider-parent .carousel-slider{
        height: auto;
    }

    .rtl .slider-parent .carousel .slide .legend{
      bottom: 55%;
      text-align: right;
      background: transparent;
      direction: rtl;
      opacity: 1;
    }

    .slider-parent .legend h6{
        color: #FFFFFF;
        margin: 10px 0;
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.5rem;
        display: none;
      }
    
      .slider-parent .legend h5{
        color: #FFFFFF;
        margin: 10px 0;
        font-weight: 700;
        line-height: 1.2;
    font-size: 1.5rem;
      }
    
      .slider-parent .legend p{
        color: #FFFFFF;
        margin: 10px 0;
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.1rem;
      }
    
      .slider-parent .legend button{
        background: #2F6DB5;
        color: white;
        border: none;
        font-weight: 700;
        line-height: 1.2;
        font-size: 1.1rem;
        padding: 10px 20px;
        border-radius: 5px;
        margin: 15px 0;
      }

      .slider-parent .legend h6{
        width: 100%;
      }
    
      .slider-parent .legend h5{
        width: 100%;
      }
    
      .slider-parent .legend p{
        width: 100%;
      }
    
  }
