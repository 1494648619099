.navbar {
  background: #2F6DB5;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 9999999999;
}

.parent-notification {
  cursor: pointer;
  position: relative;
}


.parent_links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links,
#basic-nav-dropdown {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #fff!important;
  justify-content: center;
  margin: 0 10px;
  transition: all 0.2s ease;
  padding: 8px 10px;
}

.navbar .active-link {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #fff!important;
  justify-content: center;
  margin: 0 10px;
  transition: all 0.2s ease;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
  padding: 8px 10px;
  border-radius: 5px;
}


.drive-btn {
  background: #010202;
  border-radius: 5px;
  border: none;
  padding: 9px 20px;
  color: white;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
  margin: 0 10px;
}

.ltr .not-mob-logo{
  padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.rtl .not-mob-logo{
  padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-left: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.mob-logo {
  display: none;
}

.navbar-toggler:focus{
  box-shadow : none;
}



.navbar-content{
  width: 70%;
}

.navbar .dropdown .btn-primary{
  background: transparent !important;
  border: none !important;
}

.navbar .drop-language .dropdown-toggle::after{
  display: none !important;
}

.rtl .dropdown-toggle::after{
  margin-right: 5px ;
}

.ltr .dropdown-toggle::after{
  margin-left: 5px;
}


.navbar .drop-language{
  margin: 0 20px;
}

.navbar .drop-language .dropdown-menu{
  --bs-dropdown-min-width: auto !important;
}

.rtl .navbar .drop-links .dropdown-menu{
  right: 0 !important;
}

.navbar .nav-btns a{
  margin: 0 8px;
}

.navbar .dropdown a{
  text-decoration: none;
}

.navbar .drop-links div{
  text-align: center;

  transition: all 0.5s ease;
}

.navbar .drop-links p{
  padding: 5px 0;
  transition: all 0.2s ease;
  margin: 5px 0;
  color: white;
}

.navbar .drop-links .dlink1:hover p{
 background: #010202;
}

.navbar .drop-links .dlink2:hover p{
  background: #010202;
 }

 .navbar .active-dlink1 p,.navbar .active-dlink2 p{
  background: #010202;
 }

 .navbar .drop-links .dropdown-menu {
  position: absolute;
 top: 50px;
  border-radius: none !important;
    background-color: #2F6DB5;
  
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.navbar .active-drop-links .nav-link {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0,0,0,.15);
  border-radius: 5px;
}


@media screen and (max-width:991px) {
  .not-mob-logo {
    display: none;
  }

  .mob-logo {
    display: block;
  }

  .parent_links {
    margin: 20px 0;
  }
  .navbar-content{
    flex-direction: column;
width: 100%;
  }

  .drive-btn {
    padding: 8px 10px;
  }

  .navbar .drop-links .dropdown-menu{
    top: auto;
  }

  .links{
margin-bottom: 10px;
  }
#basic-nav-dropdown {
margin-top: 20px;
}

.navbar-content{
  align-items: flex-start !important;
}
}