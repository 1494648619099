.safe-limo{
    padding: 40px 0;
}

.ltr .safe-limo img{
    width: 100%;
    border-radius: 10px;
}

.rtl .safe-limo img{
    width: 100%;
    border-radius: 10px;
}

.safe-limo h5{
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    margin-bottom: 30px;
}

.safe-limo .limo-feature{
    margin-bottom: 30px;
}

.safe-limo h6{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-top: 5px;
}

.safe-limo p{
    line-height: 1.5;
    font-size: 1.1rem;
}

.safe-limo .grid-sec{
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 100px;
}

@media screen and (max-width: 768px) {
    .safe-limo .grid-sec{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        display: flex;
        flex-direction: column-reverse;
    }

    .safe-limo .col-sec{
        margin-bottom: 20px !important;
    }

    .safe-limo{
        padding-bottom: 0;
    }

    .safe-limo .sec-img{
        width: 65%;
        margin: auto;
    }
}
