.ride-parent img {
    width: 200px;
}

.ride-parent h6 {
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.ride-parent p {
    line-height: 1.5;
    font-size: 1.1rem;
    font-weight: 400;
    color: #6b6d70;
}

.ride-parent .col-md-4 {
    text-align: center;
}