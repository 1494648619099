
.drive-header .header{
    background-image: url("../../Assets/slide8.jpg");
   width: 100%;
   height: 300px;
   background-position: center;
   background-size: cover;
   position: relative;
}

.drive-header .transperant-bg{
    background-color: rgba(0, 0, 0, 0.67);
    width: 100%;
    height: 300px;
 }

 .drive-header .header h5{
      color: white;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
  }

  .drive-header .head-content{
    background-color: #11304e69;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    text-align:center;
    height: 100%;
    padding: 20px;
  }

  .drive-header h4{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    color: white;
    width: 70%;
    margin: auto;
  }

  .drive-header button{
    background: #2F6DB5;
    color: white;
    border: none;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 15px 0;
  }

  @media screen and (max-width: 768px) {
  .drive-header h4{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
    color: white;
    width: 100%;
    margin: auto;
  }
  }