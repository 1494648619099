.safety-section3 {
    padding: 40px 0;
}

.safety-section3 h5{
    color: #010202;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 35px;
}

.safety-section3 p{
    color: #777E90;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.1rem;
}

.safety-section3 .card-title{
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.5rem;
}

.safety-section3 img{
    width: 100%;
    height: 400px;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
}

.safety-section3 .card{
    border: none !important;
    border-radius: none !important;
    text-align: center;
    --bs-card-inner-border-radius:none !important;
}

.safety-section3 .card .card-body{
    padding: 20px 140px !important;
}

.safety-section3 .carousel-safety{
    width: 90%;
}

.safety-section3 .carousel{
    display: none;
}

.carousel-safety .carousel-slider {
    display: block !important;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
}

.safety-section3 .carousel-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
}

.safety-section3 .selected{
    opacity: 1 !important;
}

.safety-section3 .slide{
    opacity: 0.1;
}

.safety-section3 .control-dots{
    display: none;
}

.carousel.carousel-slider .control-arrow{
    margin: 80px 0 250px 0 !important;
}

@media screen and (max-width: 768px) {
    .safety-section3 .card .card-body{
        padding: 20px !important;
    }

    .carousel.carousel-slider .control-arrow{
        margin: 80px 0 400px 0 !important;
    }

    .safety-section3 .carousel-safety{
        width: 100%;
    }

    .safety-section3 img{
        height: 100%;
    }
}

