.story-section1 .section1 {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}


.story-section1 .section1 .col-md-6 {
  display: flex;
  flex-direction: column;
}

.story-section1 .section1 .part1,
.section1 .part2 {
  background: white;
  flex: 1;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.1);
}

.story-section1 .section1 h3,
.story-section1 .section1 h1 {
  color: black;
}

.story-section1 .section1 h1 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 2rem;
  margin-bottom: 10px;
}

.story-section1 .section1 h3 {
  line-height: 1.5;
  font-size: 1.1rem;
  font-weight: 400;
  color: #6b6d70;
}

.story-section1 .section1 .icon {
  font-size: 60px;
  margin-bottom: 20px;
}